.cj-panel.panel {
  width: 768px;
  margin: 5em auto;
}

.example {
  text-decoration: underline;
  text-decoration-style: dashed;
}

/* vertically align radio button labels and the text beneath them */
.schedule-type-description {
  margin-left: 2.2rem;
}

/* Move radio button labels a little to the right */
.select-start-date-type .vsx-choice-input input[type="radio"] {
  margin-right: 5px;
}

/* Improve positioning of label "Choose a schedule type" */
.select-start-date-type > label {
  display: inline-block;
  margin: 0 0 0.5rem 0.4rem;
}

/* Fix Tippy popping up in the wrong place for the examples */
.schedule-type-description .vsx-popover-wrapper {
  flex-direction: initial;
}

/* Get the "Active program terms" label to align with its dynamic content */
.active-program-terms-row span {
  line-height: unset;
}
