#root {
  display: flex;
  height: 100vh;
  justify-content: center;
}

hr,
ul {
  margin-top: unset;
  margin-bottom: unset;
}

span.vs-spinner.vs-spinner-small {
  padding: unset;
}

.cj-panel.panel-body {
  padding: 24px;
}

/* fixes for TopNavLayout */

.vsx-top-nav-layout-title {
  color: white;
  margin-top: 1em;
  margin-bottom: 1em;
}

.vsx-top-nav-layout-cancel-action .vsx-btn-child {
  font-size: large;
}

/* end of fixes for TopNavLayout */
